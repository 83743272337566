import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Approve from "../Assign Project To Member/AssignProjectToMember";
import CreateProject from "../CreatPro/CreateProject";
import QueryProjects from "../QueryProjects/QueryProjects";
import TimeTrackingData from "../TimeTrackingData/TimeTrackingData";
import CreateUsers from "../CreateUsers/CreateUsers";
import Profile from "../Profile/Profile";
import Reset from "../Reset/Reset";
import Approvetime from "../Approve/Approve";
import Users from "../TimeTracking/time-tracking";
import styles from "./Admin.module.css";
import All from "../AllProjects/AllProjects";
import OnlineUser from "../OnlineUser/OnlineUser";
import AllUsers from "../AllUsers/AllUsers";

const Admin: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "AssignProject":
        return <Approve />;
        case "Approve":
          return <Approvetime />;
      case "CreateProject":
        return <CreateProject />;
      case "Reset":
        return <Reset />;
      case "CreateUser":
        return <CreateUsers />;
      case "UserReport":
        return <QueryProjects />;
      case "Tracker":
        return <Users />;
      case "ProjectReport":
        return <TimeTrackingData />;
      case "Profile":
        return <Profile />;
      case "All":
        return <All />;
      case "OnlineUser":
        return <OnlineUser />;
        case "AllUsers":
          return <AllUsers />;
      default:
        return null;
    }
  };

  return (
    <section className={styles.bg}>
      <Card className={styles.card}>
        <CardContent>
          {/* <Button variant="contained" style={{ backgroundColor: "#EE854E", color: "#fff" }}>
            Admin Menu
          </Button> */}
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ backgroundColor: "#2C6556" }}>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: "#EE854E" }} />}>
              <h3 style={{ color: "#EE854E" }}>Time Tracking</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Button onClick={() => setSelectedItem("Tracker")} style={{ color: "#EE854E" }}>Tracker</Button>
                <Button onClick={() => setSelectedItem("Approve")} style={{ color: "#EE854E" }}>Approve</Button>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ backgroundColor: "#3D7164" }}>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: "#EE854E" }} />}>
              <h3 style={{ color: "#EE854E" }}>Projects</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Button onClick={() => setSelectedItem("AssignProject")} style={{ color: "#EE854E" }}>Assign Project</Button>
                <Button onClick={() => setSelectedItem("CreateProject")} style={{ color: "#EE854E" }}>Create Project</Button>
                <Button onClick={() => setSelectedItem("ProjectReport")} style={{ color: "#EE854E" }}>Project Report</Button>
                <Button onClick={() => setSelectedItem("All")} style={{ color: "#EE854E" }}>All Projects</Button>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ backgroundColor: "#4D7D71" }}>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: "#EE854E" }} />}>
              <h3 style={{ color: "#EE854E" }}>Users</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Button onClick={() => setSelectedItem("CreateUser")} style={{ color: "#EE854E" }}>Create Users</Button>
                <Button onClick={() => setSelectedItem("UserReport")} style={{ color: "#EE854E" }}>User Report</Button>
                <Button onClick={() => setSelectedItem("OnlineUser")} style={{ color: "#EE854E" }}>Online User</Button>
                <Button onClick={() => setSelectedItem("AllUsers")} style={{ color: "#EE854E" }}>All Users</Button>

              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} style={{ backgroundColor: "#80A29A" }}>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: "#EE854E" }} />}>
              <h3 style={{ color: "#EE854E" }}>Settings</h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Button onClick={() => setSelectedItem("Profile")} style={{ color: "#EE854E" }}>Profile</Button>
                <Button onClick={() => setSelectedItem("Reset")} style={{ color: "#EE854E" }}>Reset</Button>
              </div>
            </AccordionDetails>
          </Accordion>
          
          {/* Render selected component */}
          {renderContent()}
        </CardContent>
      </Card>
    </section>
  );
};

export default Admin;
