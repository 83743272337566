import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({ baseURL: apiURL });

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
