import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MyIcon from './assets/icons/online.png'; // Importing the online icon

const OnlineUser: React.FC = () => {
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { entityId } = useSelector((state: any) => state.app.allData);
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const url = `${apiURL}/api/onlineuser/?entityId=${entityId}`;

    axiosInstance
      .get(url)
      .then((response) => {
        console.log("Success:", response.data);
        setOnlineUsers(response.data);
        dispatch(
          uiActions.updateNotification({
            message: "Fetched online users successfully",
            level: "success",
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error.response ? error.response.data : error.message);
        dispatch(
          uiActions.updateNotification({
            message: "Failed to fetch online users",
            level: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiURL, entityId, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#F7F3F0', // Optional background for the page
      }}
    >
      {/* Header */}
      <Typography variant="h4" component="h2" sx={{ color: '#EE854E', mb: 2 }}>
        Online Users
      </Typography>

      {/* Card */}
      <Card
        sx={{
          backgroundColor: '#EE854E73', // Card background color
          width: '90%', // Responsive width
          maxWidth: '600px', // Maximum width for the card
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for the card
        }}
      >
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {onlineUsers.map((user, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                  <img src={MyIcon} alt="FAQ Icon" style={{ width: 30, height: 30, marginRight: '8px' }} />                  {user}
                </li>
              ))}
            </ul>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default OnlineUser;
