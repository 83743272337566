import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../Utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import styles from "./Approve.module.css";

interface Member {
  id: number;
  name: string;
}

interface Project {
  id: number;
  name: string;
}

const Approvetime: React.FC = () => {
  const [projectId, setProjectId] = useState<number | null>(null);
  const app = useSelector((state: any) => state.app.allData); // Adjust type as per your store structure
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";

  const [memberAnchorEl, setMemberAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [usernames, setUsernames] = useState<Member[]>([]);
  const [memberIdMap, setMemberIdMap] = useState<{ [name: string]: number }>(
    {}
  );
  const [projectIdMap, setProjectIdMap] = useState<{ [name: string]: number }>(
    {}
  );
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [buttonText, setButtonText] = useState<string>("Display");
  const [isTiming, setIsTiming] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<{ data: any[] } | null>(
    null
  );

  const { userId, entityId } = useSelector((state: any) => state.app.allData); // Adjust type as per your store structure

  const handleClickMember = (event: React.MouseEvent<HTMLElement>) => {
    setMemberAnchorEl(event.currentTarget);
  };

  const handleCloseMembers = (member: string | null) => {
    setMemberAnchorEl(null);
    if (member && (!selectedMember || selectedMember.name !== member)) {
      const memberId = memberIdMap[member];
      setSelectedMember({ name: member, id: memberId });
    }
  };

  const handleClickProject = (event: React.MouseEvent<HTMLElement>) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const fetchUsernames = async (projectId: number) => {
    try {
      const response = await axiosInstance.post(
        "/api/list_members_with_unapproved_time_entries/",
        {
          projectId,
          entityId,
        }
      );
      const { isOk, message, members } = response.data;
      if (isOk && members) {
        const idMap: { [name: string]: number } = {};
        members.forEach((member: Member) => {
          idMap[member.name] = member.id;
        });
        setUsernames(members);
        setMemberIdMap(idMap);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error("Error fetching usernames:", error.message);
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiURL}/api/projects/unapproved-time-entries/`,
          {
            params: {
              entity_id: entityId,
            },
          }
        );
        const { isOk, message, projects } = response.data;

        if (isOk && projects) {
          setProjectList(projects);
          const idMap: { [name: string]: number } = {};
          projects.forEach((project: Project) => {
            idMap[project.name] = project.id;
          });
          setProjectIdMap(idMap);
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error: any) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchProjects();
  }, [dispatch, apiURL, entityId]);

  const handleCloseProject = (project: string | null) => {
    setProjectAnchorEl(null);
    if (project) {
      const projectId = projectIdMap[project];
      setSelectedProject({ name: project, id: projectId });
      setProjectId(projectId);
      fetchUsernames(projectId); // Fetch usernames for the selected project
    }
  };

  const handleAdd = () => {
    if (!selectedProject) {
      console.error("Please select a project.");
      return;
    }

    if (!selectedMember) {
      console.error("Please select a member.");
      return;
    }

    const projectId = projectIdMap[selectedProject.name];
    const memberId = selectedMember.id;

    axiosInstance
      .get(`${apiURL}/api/approve_details/`, {
        params: {
          member_id: memberId,
          project_id: projectId,
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        setResponseData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleApprove = (entryId: number) => {
    axiosInstance
      .get(`/api/approve/?pk=${entryId}`)
      .then((response) => {
        console.log("Entry approved:", response.data);
        setResponseData((prevData) => ({
          ...prevData!,
          data: prevData!.data.filter((entry) => entry.pk !== entryId),
        }));
      })
      .catch((error) => {
        console.error("Error approving entry:", error);
      });
  };

  const handleDecline = (entryId: number) => {
    axiosInstance
      .get(`/api/disapprove/?pk=${entryId}`)
      .then((response) => {
        console.log("Entry declined:", response.data);
        setResponseData((prevData) => ({
          ...prevData!,
          data: prevData!.data.filter((entry) => entry.pk !== entryId),
        }));
      })
      .catch((error) => {
        console.error("Error declining entry:", error);
      });
  };

  const convertUTCToLocal = (utcTime: string) => {
    const localTime = new Date(`${utcTime}-00:00`);
    console.log(
      `Converting UTC time ${utcTime} to local time: ${localTime.toLocaleString()}`
    );
    return localTime.toLocaleString();
  };

  return (
    <>
      <section className={styles.bg}>
        <Button
          className={styles.menu}
          id="project-button"
          aria-controls={projectAnchorEl ? "project-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={projectAnchorEl ? true : undefined}
          onClick={handleClickProject}
          disabled={isTiming}
        >
          {selectedProject ? selectedProject.name : "Select Project"}
        </Button>
        <Menu
          id="project-menu"
          anchorEl={projectAnchorEl}
          open={Boolean(projectAnchorEl)}
          onClose={() => handleCloseProject(null)}
          MenuListProps={{
            "aria-labelledby": "project-button",
          }}
        >
          {projectList.map((project) => (
            <MenuItem
              className={styles.me}
              key={project.id}
              onClick={() => handleCloseProject(project.name)}
            >
              {project.name}
            </MenuItem>
          ))}
        </Menu>

        <Button
          className={styles.menu1}
          id="basic-button"
          aria-controls={memberAnchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClickMember}
        >
          {selectedMember ? selectedMember.name : "Select Member"}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={memberAnchorEl}
          open={Boolean(memberAnchorEl)}
          onClose={() => handleCloseMembers(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {usernames.map((member) => (
            <MenuItem
              className={styles.me}
              key={member.id}
              onClick={() => handleCloseMembers(member.name)}
            >
              {member.name}
            </MenuItem>
          ))}
        </Menu>
        <br />
        <Button
          className={styles.btn}
          variant="contained"
          color="success"
          onClick={handleAdd}
        >
          {buttonText}
        </Button>

        {responseData && responseData.data.length > 0 && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Task</th>
                <th>Approve</th>
                <th>Decline</th>
                <th>Automatic</th>
              </tr>
            </thead>
            <tbody>
              {responseData.data.map((entry, index) => {
                const rowClassName = entry.auto
                  ? styles.blurGreen
                  : styles.blurRed;

                return (
                  <tr key={index} className={rowClassName}>
                    <td data-label="Start Time">
                      {new Date(entry.startTime).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}
                    </td>
                    <td data-label="End Time">
                      {new Date(entry.endTime).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}
                    </td>
                    <td data-label="Duration">{entry.duration}</td>
                    <td data-label="Task">{entry.task}</td>
                    <td data-label="Approve">
                      <Button
                        variant="contained"
                        color="success"
                        className={styles.tableButton}
                        onClick={() => handleApprove(entry.pk)}
                      >
                        Approve
                      </Button>
                    </td>
                    <td data-label="Decline">
                      <Button
                        variant="contained"
                        color="error"
                        className={styles.tableButton}
                        onClick={() => handleDecline(entry.pk)}
                      >
                        Decline
                      </Button>
                    </td>
                    <td data-label="Automatic">
                      {entry.auto !== null && entry.auto !== undefined
                        ? entry.auto.toString()
                        : "N/A"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {responseData && responseData.data.length === 0 && (
          <p>No data available for selected project and member.</p>
        )}
      </section>
    </>
  );
};

export default Approvetime;
