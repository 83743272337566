import { configureStore } from "@reduxjs/toolkit";
// slices
import uiSlice from "./ui/ui-slice";
import appSlice from "./app/app";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    app: appSlice,
  },
});

export default store;
