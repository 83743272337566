import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

type IProps = {
  label: string;
  timeChangeHandler: (evt: any) => void;
  dateTime: any;
  minDateTime?: any; 
};

export default function CommonlyUsedComponents({
  label,
  timeChangeHandler,
  dateTime,
  minDateTime, 
}: IProps) {
  const disableFutureDates = (date: any) => {
    return date.isAfter(dayjs());
  };

  const shouldDisableTime = (time: any) => {
    if (minDateTime) {
      return dayjs(time).isBefore(dayjs(minDateTime));
    }
    return false;
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <DemoItem label={label}>
            <DateTimePicker
              onChange={timeChangeHandler}
              value={dayjs(dateTime)}
              shouldDisableDate={disableFutureDates}
              shouldDisableTime={shouldDisableTime} 
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}
