import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import axiosInstance from "../Utils/Utils";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./QueryProjectsUser.module.css";

interface User {
  id: number;
  username: string;
}

interface Project {
  id: number;
  name: string;
}

interface QueriedProject {
  project_id: number;
  project_name: string;
  total_duration: string;
}

const QueryProjectsUser: React.FC = () => {
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";

  const [memberAnchorEl, setMemberAnchorEl] = useState<null | HTMLElement>(null);
  const [memberIdMap, setMemberIdMap] = useState<{ [key: string]: number }>({});
  const [selectedMembers, setSelectedMembers] = useState<User[]>([]);
  const [usernames, setUsernames] = useState<User[]>([]);
  const [queriedProjects, setQueriedProjects] = useState<QueriedProject[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
  const navigate = useNavigate();
  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [buttonText, setButtonText] = useState<string>("ADD");
  const [projectName, setProjectName] = useState<string>("");
  const [projectIdMap, setProjectIdMap] = useState<{ [key: string]: number }>({});
  const app = useSelector((state: any) => state.app.allData);
  const { userId, userName } = useSelector((state:any) => state.app.allData);

  const handleClickMember = (event: React.MouseEvent<HTMLElement>) => {
    setMemberAnchorEl(event.currentTarget);
  };

  const handleCloseMember = (member: string | null) => {
    setMemberAnchorEl(null);
    if (member && memberIdMap[member]) {
      const memberId = memberIdMap[member];
      setSelectedMembers([{ username: member, id: memberId }]);
      setQueriedProjects([]); // Clear queried projects when a new member is selected
      setShowTable(false); // Hide the table until a new query is made
    }
  };

  const handleQuerySelectorClick = async () => {
      try {

        const response = await axiosInstance.get(
          `${apiURL}/api/memberUser/${userId}/projects/`
        );
        const { isOk, message } = response.data;
        if (isOk) {
          if (response.data.projects) {
            setQueriedProjects(response.data.projects);
          }
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error: any) {
        console.error("Error fetching projects:", error.message);
      }
      setShowTable(true);

      // const selectedMemberId = memberIdMap[selectedMembers[0].username];
      // setSelectedMemberId(selectedMemberId);
    
  };

  // const handleClickProject = (event: React.MouseEvent<HTMLElement>) => {
  //   setProjectAnchorEl(event.currentTarget);
  // };

  // useEffect(() => {
  //   const fetchUsernames = async () => {
  //     try {
  //       const response = await axiosInstance.post(
  //         `${apiURL}/api/get_all_usernames/`,
  //         {
  //           entityId: app.entityId,
  //         }
  //       );
  //       const { isOk, message } = response.data;
  //       if (isOk) {
  //         if (response.data.users) {
  //           const users: User[] = response.data.users;
  //           const idMap: { [key: string]: number } = {};
  //           users.forEach((user) => {
  //             idMap[user.username] = user.id;
  //           });
  //           setUsernames(users);
  //           setMemberIdMap(idMap);
  //         }
  //       } else {
  //         dispatch(
  //           uiActions.updateNotification({
  //             message: message,
  //             level: "error",
  //           })
  //         );
  //       }
  //     } catch (error: any) {
  //       console.error("Error fetching usernames:", error.message);
  //     }
  //   };

  //   // const fetchProjects = async () => {
  //   //   try {
  //   //     const response = await axiosInstance.get(`${apiURL}/api/get_all_projects/`);
  //   //     const { isOk, message } = response.data;
  //   //     if (isOk) {
  //   //       if (response.data.projects) {
  //   //         const projects: Project[] = response.data.projects;
  //   //         setProjectList(projects);
  //   //         const idMap: { [key: string]: number } = {};
  //   //         projects.forEach((project) => {
  //   //           idMap[project.name] = project.id;
  //   //         });
  //   //         setProjectIdMap(idMap);
  //   //       }
  //   //     }
  //   //   } catch (error: any) {
  //   //     console.error("Error fetching projects:", error.message);
  //   //   }
  //   // };

  //   fetchUsernames();
  //   // fetchProjects();
  // }, [apiURL, app.entityId, dispatch]);

  // const handleCloseProject = (project: string | null) => {
  //   setProjectAnchorEl(null);
  //   if (project && projectIdMap[project]) {
  //     setSelectedProject({ name: project, id: projectIdMap[project] });
  //   }
  // };

  // const handleAdd = () => {
  //   if (!selectedProject) {
  //     console.error("Please select a project.");
  //     return;
  //   }

  //   const projectId = projectIdMap[selectedProject.name];
  //   const memberIds = selectedMembers.map((member) => member.id);

  //   const requestData = {
  //     projectId: projectId,
  //     memberIds: memberIds,
  //   };

  //   axiosInstance
  //     .post(`${apiURL}/api/add_project_members/`, requestData)
  //     .then((response) => {
  //       console.log("Success:", response.data);
  //       setButtonText("ADDED");
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  // const handleReset = () => {
  //   setProjectName("");
  //   setSelectedMembers([]);
  //   setSelectedProject(null);
  //   setButtonText("ADD");
  // };

  return (
    <>
      <section className={styles.bg}>
        {/* <Button
          className={styles.menu1}
          id="basic-button"
          aria-controls={memberAnchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClickMember}
        >
          {selectedMembers.length === 0
            ? "Select Member"
            : selectedMembers[0].username}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={memberAnchorEl}
          open={Boolean(memberAnchorEl)}
          onClose={() => handleCloseMember(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {usernames.map((user) => (
            <MenuItem
              className={styles.me}
              key={user.id}
              onClick={() => handleCloseMember(user.username)}
            >
              {user.username}
            </MenuItem>
          ))}
        </Menu> */}
        <div>
          <button
            className={styles.btnzz}
            onClick={handleQuerySelectorClick}
            // disabled={selectedMembers.length === 0}
          >
            Query Projects
          </button>
          {showTable && (
            <table className={styles.tb}>
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Total Duration</th>
                </tr>
              </thead>
              <tbody>
                {queriedProjects.map((project, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href="../MyReport"
                        onClick={(e) => {
                          e.preventDefault();
                            navigate("../MyReport", {
                              state: {
                                projectid: project.project_id,
                                memberId: userId,
                              },
                            });
                          
                        }}
                      >
                        {project.project_name}
                      </a>
                    </td>
                    <td>{project.total_duration.slice(4)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </section>
    </>
  );
};

export default QueryProjectsUser;
