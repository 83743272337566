import React from "react"; // Import React
import Lottie from "react-lottie";

interface DotLottieProps {
  animation: {};
}
const DotLottie: React.FC<DotLottieProps> = ({ animation }) => {
  // Specify the type of DotLottie as React.FC<DotLottieProps>
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      style={{
        width: window.innerWidth > 600 ? 400 : 250,
        height: window.innerWidth > 600 ? 400 : 250,
        position: window.innerWidth > 600 ? "static" : "absolute",
        top: window.innerWidth > 600 ? 0 : "10rem",
      }}
    />
  );
};

export default DotLottie;
