import React, { Component, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
// import SimpleBottomNavigation from '../footer/Footer';
import Header from '../Header/Header';

interface LayoutState {
  // Define state if necessary
}

export default class Layout extends Component<{}, LayoutState> {
  // Define handleLogout function here if needed

  render(): ReactNode {
    // Example handleLogout function
    const handleLogout = () => {
      // Implement your logout logic here
      console.log("Logging out...");
    };

    return (
      <div>
        <Header handleLogout={handleLogout} />
        <Outlet />
        {/* <SimpleBottomNavigation /> */}
      </div>
    );
  }
}
