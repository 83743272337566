import { Button, TextField } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import * as React from "react";
import { useState } from "react";
import styles from "./Admin.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";

const CreateUsers: React.FC = () => {
  const apiURL = process.env.REACT_APP_API_URL || ''; // Ensure fallback if variable is undefined

  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("ADD");
  const [role, setRole] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const app = useSelector((state: any) => state.app.allData);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const handleLogin = async () => {
    try {
      let newRole: string;

      if (username === "admin" && password === "admin") {
        newRole = "admin";
      } else {
        newRole = "user";
      }

      setRole(newRole);

      const response = await axiosInstance.post(`${apiURL}/api/add_member/`, {
        username,
        email,
        password,
        role: newRole,
        entityId: app.entityId,
      });
      const { isOk, message } = response.data;
      if (isOk) {
        setButtonText("ADDED");
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error:any) {
      console.error("Error during login:", error);
      if (error.response) {
        console.error("Detailed server error:", error.response.data.error);
        dispatch(
          uiActions.updateNotification({
            message: error.response.data.error,
            level: "error",
          })
        );
      }
    }
  };

  const handleResetUserForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setProjectName("");
    setSelectedMembers([]);
    setButtonText("ADD");
  };

  return (
    <>
      <section className={styles.bg}>
        <TextField
          className={styles.text}
          label="Username:"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <TextField
          className={styles.text}
          label="Email:"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <TextField
          className={styles.text}
          label="Password:"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <Button
          className={styles.btn3}
          variant="contained"
          color="success"
          onClick={handleLogin}
          disabled={
            !username ||
            !password ||
            !validateEmail(email) ||
            buttonText === "ADDED"
          }
        >
          {buttonText}
        </Button>

        <Button
          className={styles.btn1}
          variant="contained"
          color="secondary"
          onClick={handleResetUserForm}
        >
          Reset
        </Button>
      </section>
    </>
  );
};

export default CreateUsers;
