import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Chip, OutlinedInput, SelectChangeEvent, CircularProgress } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import { uiActions } from "../../store/ui/ui-slice";
import styles from "./Admin.module.css";

const CreateProject = () => {
  const apiURL = process.env.REACT_APP_API_URL || ""; // Provide a default value
  const { entityId } = useSelector((state: any) => state.app.allData);
  const [projectName, setProjectName] = useState<string>(""); // Specify the type for projectName
  const [memberIds, setMemberIds] = useState<number[]>([]); // State for member IDs
  const [users, setUsers] = useState<{ id: number; username: string }[]>([]); // State for users
  const [isLoading, setIsLoading] = useState<boolean>(false); // State for loading indicator

  const dispatch = useDispatch();

  // Fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.post(apiURL + "/api/get_all_user_create/", { entityId });
        if (response.data.isOk) {
          setUsers(response.data.users);
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message,
              level: "error",
            })
          );
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiURL, entityId, dispatch]);

  const handleCreate = () => {
    setIsLoading(true); // Set loading to true when the API call starts

    const requestData = {
      projectName: projectName,
      entityId: entityId,
      memberIds: memberIds,
    };

    axiosInstance
      .post(apiURL + "/api/add_project/", requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
      })
      .catch((error) => {
        console.error("Error creating project:", error);
        dispatch(
          uiActions.updateNotification({
            message: "Failed to create project",
            level: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false); // Reset loading to false after API call completes
      });
  };

  const handleMemberChange = (event: SelectChangeEvent<number[]>) => { 
    const {
      target: { value },
    } = event;
    setMemberIds(typeof value === "string" ? value.split(",").map(Number) : value as number[]);
  };

  return (
    <>
      <section className={styles.bg}>
        <TextField
          className={styles.txt}
          id="outlined-basic"
          label="Project Name"
          variant="outlined"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          autoComplete="off"
        />
        <FormControl fullWidth variant="outlined" className={styles.txt2}>
          <InputLabel id="members-select-label">Members</InputLabel>
          <Select
            labelId="members-select-label"
            multiple
            value={memberIds}
            onChange={handleMemberChange}
            input={<OutlinedInput label="Members" />}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => {
                  const user = users.find((user) => user.id === value);
                  return user ? <Chip key={value} label={user.username} /> : null;
                })}
              </div>
            )}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          className={styles.bt}
          variant="contained"
          color="secondary"
          onClick={handleCreate}
          disabled={!projectName || memberIds.length === 0 || isLoading} // Disable if loading or form incomplete
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Create"}
        </Button>
      </section>
    </>
  );
};

export default CreateProject;
