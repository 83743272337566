import React, { useState } from "react"; 
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { appActions } from "../../store/app/app";
import { uiActions } from "../../store/ui/ui-slice";

// Define types for response data
interface LoginResponse {
  isOk: boolean;
  message: string;
  code: number;
  token: string;
  userId: string;
  entityId: string;
  email: string;
  entityName: string;
  userName: string;
  role: string;
}

const apiURL = process.env.REACT_APP_API_URL;

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    try {
      const req = {
        email: email.toLowerCase(),
        password: password,
      };
  
      axios
        .post<LoginResponse>(`${apiURL}/api/login/`, req)
        .then((response) => {
          const {
            isOk,
            message,
            code,
            token,
            userId,
            entityId,
            email: userEmail,
            entityName,
            userName,
            role,
          } = response.data;
          console.log(userId);
  
          if (isOk && code === 200) {
            // Handle successful login
            dispatch(
              appActions.setAppData({
                userId: userId,
                entityId: entityId,
                entityName: entityName,
                userName: userName,
                email: userEmail,
              })
            );

            localStorage.setItem("token", token);
  
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
  
            const expirationDateUTC = new Date(decodedToken.exp * 1000);
            if (role === "admin") {
              navigate("../Admin");
            } else {
              navigate("../User");
            }
  
            const timeUntilExpiration = expirationDateUTC.getTime() - Date.now();
            setTimeout(() => {
              localStorage.removeItem("token");
              navigate("/login");
            }, timeUntilExpiration);

            // New API call to status after successful login
            const statusReq = {
              email: email,
              message: message,
            };

            axios
              .post(`${apiURL}/api/status/`, statusReq)
              .then((statusResponse) => {
                console.log("Status Response:", statusResponse.data);
                // Handle the status response as needed
              })
              .catch((statusError) => {
                console.error("Error during status API call:", statusError);
                if (statusError.response && statusError.response.data.error) {
                  dispatch(
                    uiActions.updateNotification({
                      message: statusError.response.data.error,
                      level: "error",
                    })
                  );
                }
              });
          } else {
            // Handle error messages
            if (code === 301 || code === 404) {
              dispatch(
                uiActions.updateNotification({
                  message: message,
                  level: "error",
                })
              );
              const statusReq = {
                email: email,
                message: message,
              };
  
              axios
                .post(`${apiURL}/api/status/`, statusReq)
                .then((statusResponse) => {
                  console.log("Status Response:", statusResponse.data);
                  // Handle the status response as needed
                })
                .catch((statusError) => {
                  console.error("Error during status API call:", statusError);
                  if (statusError.response && statusError.response.data.error) {
                    dispatch(
                      uiActions.updateNotification({
                        message: statusError.response.data.error,
                        level: "error",
                      })
                    );
                  }
                });} else if (code === 400) {
              dispatch(
                appActions.setAppData({
                  userId: userId,
                  entityId: entityId,
                  entityName: entityName,
                  userName: userName,
                  email: userEmail,
                })
              );
              navigate("../verify");
              console.log(userId);
              const statusReq = {
                email: email,
                message: message,
              };
  
              axios
                .post(`${apiURL}/api/status/`, statusReq)
                .then((statusResponse) => {
                  console.log("Status Response:", statusResponse.data);
                  // Handle the status response as needed
                })
                .catch((statusError) => {
                  console.error("Error during status API call:", statusError);
                  if (statusError.response && statusError.response.data.error) {
                    dispatch(
                      uiActions.updateNotification({
                        message: statusError.response.data.error,
                        level: "error",
                      })
                    );
                  }
                });} else {
              dispatch(
                uiActions.updateNotification({
                  message: message,
                  level: "error",
                })
              );
            }
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
          if (error.response && error.response.data.error) {
            dispatch(
              uiActions.updateNotification({
                message: error.response.data.error,
                level: "error",
              })
            );
          }
        });
    } catch (error) {
      console.error("Error during login:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error during login. Please try again.",
          level: "error",
        })
      );
    }
  };
  
  return (
    <section className={styles.bg}>
      <h2 className={styles.title}>Login</h2>
      <Card className={styles.card}>
        <CardContent>
          <div className={styles.container}>
            <div className={styles.inputContainer}>
              <label className={styles.text}>Email:</label>
              <input
                className={styles.input}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.text}>Password:</label>
              <input
                className={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              className={styles.btn}
              variant="contained"
              color="secondary"
              onClick={handleLogin}
            >
              Login
            </Button>
            <Link className={`${styles.register} nav-item nav-link active`} to="/Register">
              Register
            </Link>
          </div>
        </CardContent>
      </Card>
    </section>
  );
};

export default LoginPage;
