import Button from "@mui/material/Button";
import axiosInstance from "../Utils/Utils";
import * as React from "react";
import { useState } from "react";
import styles from "./Profile.module.css";
import TextField from "@mui/material/TextField";
// redux
import { uiActions } from "../../store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";

const Profile = () => {
  const apiURL = process.env.REACT_APP_API_URL;

  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState("");
  const [oldpass, setoldpass] = useState("");
  const [Email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationStep, setIsVerificationStep] = useState(false);

  const app = useSelector((state: any) => state.app.allData);

  console.log("id: ", app.userId);
  const handleCreate = () => {
    const requestData = {
      memberId: app.userId,
      newPassword: projectName,
      oldPassword: oldpass,
    };

    axiosInstance
      .post(apiURL + "/api/update_password/", requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
      });
  };

  const handleMail = () => {
    const requestData = {
      userId: app.userId,
      newEmail: Email,
    };

    axiosInstance
      .post(apiURL + "/api/update_email/", requestData)
      .then((response) => {
        console.log("Success:", response.data);
        if (response.data.isOk) {
          setIsVerificationStep(true);
        }
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
      });
  };

  const handleVerification = () => {
    const requestData = {
      userId: app.userId,
      verificationCode: verificationCode,
    };

    axiosInstance
      .post(apiURL + "/api/verify_email_change/", requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
        if (response.data.isOk) {
          setIsVerificationStep(false);
        }
      });
  };

  return (
    <>
      <section className={styles.bg}>
        {!isVerificationStep ? (
          <>
            <TextField
              className={styles.txt}
              id="outlined-basic"
              label="New Password"
              variant="outlined"
              value={projectName}
              type="password"
              onChange={(e) => setProjectName(e.target.value)}
            />
            <TextField
              className={styles.txt2}
              id="outlined-basic"
              label="Old Password"
              variant="outlined"
              value={oldpass}
              type="password"
              onChange={(e) => setoldpass(e.target.value)}
            />
            <Button
              className={styles.bt}
              variant="contained"
              color="secondary"
              onClick={handleCreate}
              disabled={!projectName && !oldpass}
            >
              Update Password
            </Button>
            <TextField
              className={styles.txt3}
              id="outlined-basic"
              label="New Email"
              variant="outlined"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              className={styles.bt2}
              variant="contained"
              color="secondary"
              onClick={handleMail}
              disabled={!Email}
            >
              Update Email
            </Button>
          </>
        ) : (
          <>
            <TextField
              className={styles.txt}
              id="outlined-basic"
              label="Verification Code"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Button
              className={styles.bt}
              variant="contained"
              color="secondary"
              onClick={handleVerification}
              disabled={!verificationCode}
            >
              Verify Email
            </Button>
          </>
        )}
      </section>
    </>
  );
};

export default Profile;
