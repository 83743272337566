const _DateToISOFormat = (dateStr: string, isEndDate = false): string => {
  // Split the date string to extract year, month, and day
  const [year, month, day] = dateStr.split("-").map(Number);

  // Create a Date object using the extracted values
  let dateObj = new Date(year, month - 1, day); // month is 0-based

  // If it's the end date, set time to the last possible moment of the day
  if (isEndDate) {
    dateObj.setHours(23, 59, 59, 999);
  } else {
    // Otherwise, set time to the start of the day
    dateObj.setHours(0, 0, 0, 0);
  }

  return _DateTimeToLocalISOString(dateObj);
};



function _DateTimeToLocalISOString(date: Date) {
  const tzOffset = -date.getTimezoneOffset(); // in minutes
  const sign = tzOffset >= 0 ? "+" : "-";
  const pad = (num: any) => String(num).padStart(2, "0");

  const offsetHours = pad(Math.floor(Math.abs(tzOffset) / 60));
  const offsetMinutes = pad(Math.abs(tzOffset) % 60);

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." +
    String(date.getMilliseconds()).padStart(3, "0") +
    sign +
    offsetHours +
    ":" +
    offsetMinutes
  );
}

export { _DateToISOFormat, _DateTimeToLocalISOString };
