import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { Alert } from "@mui/material";

export default function MySnackbar() {
  const dispatch = useDispatch();
  const notification: any = useSelector((state: any) => state.ui.notification);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(uiActions.updateNotification({ message: "" }));
  };

  return (
    <Snackbar
      open={notification.message ? true : false}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={{ minWidth: "250px" }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.level}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
