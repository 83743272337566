import React, { useEffect, useState } from "react";
import styles from "./assets/scss/timer.module.css";

const Timer = ({ startTime }) => {


  return (
    <div style={{ margin: "50px" }} className={styles.timerText}>
      <span>{startTime}</span>
    </div>
  );
};

export default Timer;
